export const mockProps = {
  value: 'text input',
  name: 'inputname',
  label: 'Input label',
  placeholder: 'Text hint',
  prependIcon: 'search',
  appendIcon: undefined,
  size: 'md',
  block: true,
  autocomplete: false,
  disabled: false,
  error: false,
  search: true,
  labelHidden: false,
};